<template>
  <div class="main-frame">
    <div v-if="$route.path.includes('/myPage')" class="category-title">
      홈 <img class="title-icon" src="../assets/images/icon/icon_gray_right.png"> 내 게시물 관리
      <img class="title-icon" src="../assets/images/icon/icon_gray_right.png">게시물 등록
    </div>
    <div class="item-box">
      <div class="left">
        <div class="item-img">
          <img v-if="imageFile.thumbnailUrls.length !== 0" :src=imageFile.thumbnailUrls[0]>
        </div>
        <div class="img-btn">
          <mdb-file-input md btnColor="orange"
                          btnTitle="게시물 이미지 등록"
                          accept="image/gif, image/jpeg, image/png"
                          @getValue="getFileInputValue($event)"/>
        </div>
      </div>
      <div class="item-data">
        <div class="box">
          <p>카테고리 대분류 <strong>*</strong></p>
          <input v-if="isEdit" type="text" class="custom-input-box" v-model="itemInfo.category" disabled>
          <div v-else class="select-box">
            <img class="select-icon" src="@/assets/images/icon/icon_selector.png">
            <mdb-select class="custom-input select-input" id="select-job"
                        @getValue="categorySelect($event)"
                        placeholder="대분류 선택" size="md" v-model="categoryOptions"/>
          </div>
        </div>
        <div class="box">
          <p>카테고리 소분류 <strong>*</strong></p>
          <input v-if="isEdit" type="text" class="custom-input-box" v-model="itemInfo.subCategory" disabled>
          <div v-else class="select-box">
            <img class="select-icon" src="@/assets/images/icon/icon_selector.png">
            <mdb-select class="custom-input select-input" id="select-job"
                        @getValue="subCategorySelect($event)"
                        :class="{disabled:subCategoryOptions.length === 0}"
                        placeholder="대분류부터 선택해주세요" size="md" v-model="subCategoryOptions"/>
          </div>
        </div>
        <div class="box">
          <p>제목 <strong>*</strong></p>
          <input type="text" placeholder="제목을 입력해주세요" class="custom-input-box" v-model="itemInfo.title">
        </div>
        <div class="box">
          <p>가격 <strong>*</strong></p>
          <input type="number" placeholder="숫자만 입력해주세요" class="custom-input-box" v-model.number="itemInfo.price">
        </div>
        <div class="box">
          <p>태그</p>
          <input type="text" placeholder="태그 10개까지 입력 가능 (예: #AI #웹 프론트)" class="custom-input-box" v-model="itemInfo.tag"
                 @input="tagFormat">
        </div>
      </div>
    </div>
    <div class="textarea-box box">
      <p>제품 정보 <strong>*</strong></p>
      <textarea type="text" placeholder="제품 정보를 입력해 주세요" class="custom-input-box" v-model="itemInfo.itemDetail"/>
    </div>
    <div class="textarea-box box">
      <p>제품 상세정보</p>
      <textarea type="text" placeholder="제품 추가 상세정보를 입력해 주세요" class="details custom-input-box" v-model="itemInfo.itemDetails"/>
    </div>
    <div class="btn-box">
      <button class="back-btn custom-btn" @click="$router.go(-1)">뒤로</button>
      <button v-if="isEdit" class="custom-btn" @click="addItem">게시물 수정</button>
      <button v-else class="custom-btn" @click="addItem">게시물 등록</button>
    </div>
    <Progress :isProgress="isProgress"></Progress>
  </div>
</template>

<script>
import Progress from "@/components/Progress.vue";
import {mdbSelect, mdbFileInput} from "mdbvue";
import {firestore, storage, Storages, Timestamp} from "@/firebase/firebaseConfig";
// import {storage, Storages, Timestamp} from "@/firebase/firebaseConfig";
import moment from "moment"

export default {
  name: "itemDetail",
  components: {
    Progress, mdbSelect, mdbFileInput
  },
  data() {
    return {
      isProgress: false,
      isEdit: false,
      itid: '',
      uid: this.$store.state.uid,
      itemInfo: {
        category: '',
        subCategory: '',
        price: '',
        seller: '',
        tag: '',
        thumbnail: '',
        title: '',
        itemDetail: '',
        itemDetails: '',
      },
      saveImg: {
        thumbnail: [],
      },
      imageFile: {
        thumbnail: [],
        thumbnailUrls: [],
      },
      uploadPromises: [],
      categoryOptions: [
        {text: '플러그인/라이브러리', value: 'plugin'},
        {text: '프로그램 패키지', value: 'program'},
        {text: '디자인', value: 'design'},
      ],
      subCategoryOptions: [],
    }
  },
  watch: {
    'itemInfo.category'() {
      const self = this;
      if (!self.isEdit)
        self.itemInfo.subCategory = ''
    }
  },
  mounted() {
    if (this.$route.path.includes('/edit'))
      this.isEdit = true

    this.init()
  },
  methods: {
    init() {
      const self = this;
      if (self.isEdit) {
        self.getItem()
      }
    },
    getItem() {
      const self = this;
      self.itid = self.$route.params.itid
      firestore.collection('item')
          .doc(self.itid)
          .get()
          .then((snapshot) => {
            self.itemInfo = snapshot.data()
            self.imageFile.thumbnailUrls.push(self.itemInfo.thumbnail)
            self.imageFile.thumbnail.push(self.itemInfo.thumbnail)
          })
    },
    categorySelect(value) {
      const self = this;
      self.itemInfo.category = value
      switch (value) {
        case 'plugin':
          self.subCategoryOptions = [
            {text: 'S/W 플러그인', value: 'S/W 플러그인'},
            {text: 'S/W 라이브러리', value: 'S/W 라이브러리'},
            {text: 'H/W,F/W 플러그인', value: 'H/W,F/W 플러그인'},
            {text: 'H/W,F/W 라이브러리', value: 'H/W,F/W 라이브러리'},
          ]
          break;
        case 'program':
          self.subCategoryOptions = [
            {text: 'S/W 패키지', value: 'S/W 패키지'},
            {text: 'H/W,F/W 패키지', value: 'H/W,F/W 패키지'},
            {text: '기타 패키지', value: '기타 패키지'},
          ]
          break;
        case 'design':
          self.subCategoryOptions = [
            {text: 'AI Prompt', value: 'AI Prompt'},
            {text: 'AI-generated Artwork', value: 'AI-generated Artwork'},
            {text: 'AI Model', value: 'AI Model'},
            {text: '기타 디자인', value: '기타 디자인'},
          ]
          break;
      }
    },
    subCategorySelect(value) {
      const self = this;
      self.itemInfo.subCategory = value
    },

    tagFormat() {
      let input = this.tag

      input = input.replace(/,\s/g, ' #'); // 쉼표 뒤에 띄어쓰기가 있는 경우 #로 바꿈
      if (input !== '' && input[0] !== '#') { // 첫 글자가 #가 아닌 경우
        input = '#' + input; // 문자열의 앞에 # 추가
      }
      const tagCount = (input.match(/#/g) || []).length;
      if (tagCount > 10) {
        input = input.substring(0, input.lastIndexOf('#', 139)); // 마지막 #로부터 최대 10개까지만 허용
      }
      this.tag = input; // 변경된 문자열을 입력값으로 설정
    },
    getFileInputValue(val) {
      const self = this;

      self.imageFile.thumbnailUrls.splice(0)
      self.imageFile.thumbnail = [...val];
      val.forEach((imgFile) => {
        self.imageFile.thumbnailUrls.push(window.URL.createObjectURL(imgFile))
      })
    },
    addItem() {
      const self = this;
      if (self.itemInfo.category === '') return alert('카테고리 대분류를 선택해주세요')
      if (self.itemInfo.subCategory === '' || self.itemInfo.subCategory === null) return alert('카테고리 소분류를 선택해주세요')
      if (self.itemInfo.title.trim().length === 0) return alert('제목을 정해주세요')
      if (self.itemInfo.price.length === 0) return alert('가격을 정해주세요')
      if (self.imageFile.thumbnail.length === 0) return alert('이미지를 등록해주세요')
      if (self.itemInfo.itemDetail.length === 0) return alert('제품 정보를 입력해주세요')

      self.onSend()
    },
    async onSend() {
      const self = this;

      self.isProgress = true
      self.uploadPromises = [];
      if (self.imageFile.thumbnail.length !== 0) await self.getImageFileInputValue()
      const _Data = self.itemInfo
      _Data.seller = self.$store.state.user.email.split('@')[0]
      _Data['sellerInfo'] = {
        uid: self.uid
      }
      _Data['lastDate'] = Timestamp.fromDate(new Date())

      let text = ''
      if (self.isEdit)
        text = '수정'
      else text = '등록'
      await Promise.all(self.uploadPromises)
      let docBasic = firestore.collection('item')
      if (self.isEdit) {
        docBasic = docBasic
            .doc(self.itid)
            .set(_Data, {merge: true})
      } else {
        docBasic = docBasic
            .add(_Data)
      }
      docBasic.then(() => {
        self.isProgress = false
        self.$swal.fire(`${text} 성공!`, `게시물이 ${text}되었습니다.`, '성공')
            .then(async () => {
              self.$router.go(-1);
            })
      })
    },
    getImageFileInputValue() {
      const self = this;
      const fileDate = moment().format('YYYYMMDDhhmmssSSS')
      // const fileTime = moment().format('hhmmssSSS');

      let file = self.imageFile.thumbnail;

      for (let i = 0; i < file.length; i++) {
        const storageRef = Storages.ref();
        const savePath = storageRef.child(self.itemInfo.category).child(fileDate);
        // const savePath = storageRef.child(self.itemInfo.category).child(fileDate + '/thumbnail/' + fileTime);
        const upload = savePath.put(file[i])

        // Promise를 배열에 추가합니다.
        self.uploadPromises.push(new Promise((resolve, reject) => {
          self.isProgress = true;
          upload.on('state_changed', (snapshot) => {
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
                switch (snapshot.state) {   // Storages와 storage는 storage()와 storage
                  case storage.TaskState.PAUSED: // or 'paused'
                    break;
                  case storage.TaskState.RUNNING: // or 'running'
                    break;
                }
              },
              (error) => {
                self.isProgress = false;
                console.error('실패사유는', error);
              },
              () => {
                upload.snapshot.ref.getDownloadURL().then(async (url) => {
                  self.itemInfo.thumbnail = url
                  resolve();
                }).catch((err) => {
                  console.log(err);
                  reject(err);
                });
              })
        }));
      }
    }
    ,
  }
}
</script>

<style scoped>

.main-frame {
  width: 1224px;
  margin: 0 auto;
  padding: 60px 16px;
}

.main-frame .category-title {
  text-align: start;
  color: #71717A;
  font-size: 12px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.24px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}


.main-frame .category-title .title-icon {
  width: 18px;
  height: 18px;
}

.main-frame .item-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.main-frame .item-box .item-img {
  width: 586px !important;
  height: 366px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background: #FFF;
  margin-right: 20px;
  overflow: hidden;
  border: 1px solid #E4E4E7;
}

.main-frame .item-box .item-img img {
  width: 100%;
  height: 100%;
}

.main-frame .item-box .img-btn {
  padding-right: 20px;
}

.main-frame .item-box .item-data {
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 560px;
}

.item-box .item-data :first-child.box {
  margin: 0;
}

.box {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-top: 10px;
}

.box p {
  margin-bottom: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.02em;
}

.box p strong {
  color: #EF4444;
}

.textarea-box {
  margin-top: 20px;
}

.textarea-box p {
  font-size: 16px;
  font-weight: 500;
}

.textarea-box textarea {
  resize: none;
  padding: 14px 16px;
  outline: none;
  height: 150px;
}

.textarea-box .details {
  height: 300px;
}

.textarea-box textarea:focus {
  border: 1px solid #ff8a00;
}

.main-frame .btn-box {
  margin-top: 20px;
  margin-right: 15px;
  display: flex;
  justify-content: flex-end;
}

.main-frame .btn-box button {
  width: 120px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-frame .btn-box .back-btn {
  background: #ffffff;
  color: #ff8a00;
  border: 2px solid #ff8a00;
}

textarea::placeholder,
input::placeholder {
  color: #C7C6CD;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;
}

input:disabled {
  background: #E4E4E7;
}

input:focus {
  outline: 1px solid #ff8a00;
}


/* select */
.select-box {
  position: relative;
  margin-bottom: 10px;

}


.select-box .select-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  z-index: 1;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
}

.select-input::v-deep(.caret) {
  display: none;
}

.select-input::v-deep(.select-wrapper.md-form),
.select-input::v-deep(.md-form.select-dropdown) {
  margin: 0 !important;
}

.select-input::v-deep(.select-active input) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.select-input::v-deep(.mdb-select-789397) {

}

.select-input::v-deep(.collapse-item[data-v-39a3e486]) {
  top: 100%;
}


.select-input::v-deep(.dropdown-content.select-dropdown) {
  border-radius: 20px;
}

.select-input::v-deep(.select-inner-wrapper) {
  height: 100%;
}

mb-0 options-container scrollbar-grey thin
.select-input::v-deep(.options-container[data-v-6c2c7caf]) {
  overflow-y: auto;
}

.select-input::v-deep(.select-inner-wrapper li) {
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px #E4E4E7 solid;
}

.select-input::v-deep(.form-control)::placeholder {
  color: #C7C6CD;
}

.select-input::v-deep(.select-inner-wrapper li.active) {
  background: #fafafa;
}

.select-input::v-deep(.select-inner-wrapper li.active span) {
  color: #ff8a00;
}


.select-input::v-deep(.select-inner-wrapper li span) {
  font-size: 16px;
  color: #000000;
}

.custom-input::v-deep(.mb-0.options-container.scrollbar-grey.thin) {
  max-height: 100% !important;
  overflow-y: inherit;
}

.custom-input::v-deep(.md-form .form-control) {
  font-weight: 500;
  margin-bottom: 0 !important;
  padding: 0;
}

.custom-input::v-deep(.md-form input:not(.browser-default)) {
  height: 52px;
  width: 100%;
  padding: 0 16px;
  background: #FFFFFF;
  border-radius: 20px;
  margin-bottom: 20px;
  border: 1px #E4E4E7 solid;
}

.custom-input::v-deep(.md-form input:not(.browser-default):focus) {
  border: 1px #ff8a00 solid;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

::v-deep(.file-path-wrapper input) {
  display: none;
}

.left ::v-deep(.md-form) {
  margin-top: 31px;
  margin-bottom: 0;
  height: 52px;
}

::v-deep(.btn):active {
  background: #ff8a00 !important;
}

::v-deep(.btn) {
  background: #ff8a00 !important;
  width: 100%;
  border-radius: 20px;
  font-size: 16px;
  height: 52px;
  font-weight: 700;
  margin: 0;
  display: flex;
  align-items: center;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>